import React, { memo, useRef, useCallback, useMemo, useState, useEffect } from 'react'
import { arrayOf, array, bool, number, shape as propsShape, string, oneOfType, oneOf } from 'prop-types'

import { useTheme } from 'styled-components'

import { FormElement, RadioGroup, Radio, withFormElementProps } from '@forms'
import { Flyout } from '@utilities/Flyout'
import { OnClickOutside } from '@utilities/OnClickOutside'
import { PosRelative, Box } from '@layout/BuildingBlocks'
import { OptionsList, OptionsListItem, OptionsLabelText } from './SubComponents'

import { useCursor, useKeyboardNavigation, withBadge, withButton } from '.'
import { useControllableState, useDebouncedUpdates } from 'lib/hooks'
import { isFunction } from 'lib/util'
import { getThemeValue } from 'theming'

const buildItem = (entry) => {
  const value = entry !== null && typeof entry.value !== 'undefined' ? entry.value : entry
  let label = entry.label ? entry.label : value
  if (entry !== null && typeof entry.amount !== 'undefined') {
    label += ' (' + entry.amount + ')'
  }

  return {
    label,
    value,
    disabled: !!entry.disabled,
  }
}

const groupStateToValue = (state) => {
  return state.value.radioValue.value
}

const valueToGroupState = (value, options) => {
  return {
    radioValue: { value },
    label: { value: options.find((option) => option.value === value)?.label ?? null },
  }
}

export const DropdownSelectComponent = ({
  appearance,
  children,
  debounce,
  defaultValue,
  formElementRefs,
  items,
  leftAlign,
  keepButtonText,
  name,
  onBlur,
  onChange,
  onFocus,
  optionsListMaxHeight,
  optionsListPlacement,
  optionsMinWidth,
  optionsSize,
  placeholder,
  refBadge,
  size,
  shape,
  stretch,
  value,
  disabled,
  tag,
  ...props
}) => {
  const theme = useTheme()
  const spacerBg = getThemeValue(theme, 'dropdown.spacer.bg')
  const spacerHeightTop = getThemeValue(theme, 'dropdown.spacer.height.top')
  const spacerHeightBottom = getThemeValue(theme, 'dropdown.spacer.height.bottom')

  const [initialValue] = useState(defaultValue ?? value ?? null)
  const [isFocusedBadge, setIsFocusedBadge] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const activeByArrow = useRef(false)
  const refOptionsList = useRef()
  const refOptionsListItems = useRef([])
  const refocusOnClose = useRef(false)

  const onDebouncedUpdate = useCallback(
    (value) => {
      isFunction(onChange) && onChange({ id: props.id, name, value })
    },
    [onChange, props.id, name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [internalValue, setInternalValue] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const options = useMemo(() => items.map((item) => buildItem(item)), [items])

  const formState = useMemo(() => valueToGroupState(internalValue, options), [internalValue, options])

  const internalOnChange = useCallback(
    (target) => {
      setInternalValue(groupStateToValue(target))
      if (target?.changeEmitter?.name === 'radioValue') {
        refocusOnClose.current = true
        setIsActive(false)
      }
    },
    [setInternalValue]
  )

  const onReset = useCallback(() => {
    setInternalValue(initialValue)
  }, [setInternalValue, initialValue])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = internalValue
    }
  }, [internalValue, formElementRefs])

  useEffect(() => {
    setIsFocused(isActive || isFocusedBadge)
  }, [isActive, isFocusedBadge])

  useEffect(() => {
    if (isFocused) {
      typeof onFocus === 'function' && onFocus()
    } else {
      typeof onBlur === 'function' && onBlur()
    }
  }, [isFocused, onFocus, onBlur])

  const onClickBadge = useCallback(() => {
    setIsActive((prev) => !prev)
  }, [])

  const onFocusBadge = useCallback(() => {
    setIsFocusedBadge(true)
  }, [])

  const onBlurBadge = useCallback(() => {
    setIsFocusedBadge(false)
  }, [])

  const { cursorValue, setNextCursorValue } = useCursor({
    options,
    isActive,
    refScrollContainer: refOptionsList,
    refOptions: refOptionsListItems,
  })

  const openDropdown = useCallback((byArrow) => {
    activeByArrow.current = byArrow
    setIsActive(true)
  }, [])

  const closeDropdown = useCallback((refocus = false) => {
    refocusOnClose.current = refocus
    setIsActive(false)
  }, [])

  const selectCursor = useCallback(() => {
    if (cursorValue !== internalValue) {
      setInternalValue(cursorValue)
    }
    closeDropdown(true)
  }, [cursorValue, internalValue, setInternalValue, closeDropdown])

  useKeyboardNavigation({
    name,
    focused: isFocusedBadge,
    active: isActive,
    closeDropdown,
    openDropdown,
    selectCursor,
    moveCursor: setNextCursorValue,
  })

  const closeDropdownNoFocus = useCallback(() => closeDropdown(false), [closeDropdown])

  const onClickActiveOption = useCallback(() => {
    closeDropdown(true)
  }, [closeDropdown])

  useEffect(() => {
    if (isActive) {
      setNextCursorValue(activeByArrow.current, internalValue)
      activeByArrow.current = false
    }
  }, [isActive, internalValue, setNextCursorValue])

  useEffect(() => {
    if (!isActive) {
      if (refocusOnClose.current) {
        refocusOnClose.current = false
        refBadge.current.focus()
      }
    }
  }, [isActive, refBadge])

  return (
    <FormElement name={name} value={formState} onChange={internalOnChange}>
      <OnClickOutside handler={closeDropdownNoFocus}>
        {React.cloneElement(children, {
          ...{
            appearance,
            isActive,
            leftAlign,
            disabled,
            tag,
            onBlur: onBlurBadge,
            onClick: onClickBadge,
            onFocus: onFocusBadge,
            placeholder,
            refBadge,
            shape,
            size,
            stretch,
          },
          ...(() => {
            if (keepButtonText) return
            return {
              text: formState.label.value,
            }
          })(),
        })}
        {isActive && (
          <Flyout minWidth="100%" refOpener={refBadge} placement={optionsListPlacement}>
            <PosRelative>
              <Box mt={spacerHeightTop} bg={spacerBg} />
              <OptionsList maxHeight={optionsListMaxHeight} ref={refOptionsList}>
                <RadioGroup name="radioValue" forElementName={name}>
                  {options.map((item, index) => (
                    <OptionsListItem
                      key={item.value}
                      optionsMinWidth={optionsMinWidth}
                      ref={(option) => (refOptionsListItems.current[index] = option)}
                      onClick={item.value === internalValue ? onClickActiveOption : null}
                    >
                      <Radio value={item.value} disabled={item.disabled} forElementName="radioValue">
                        <OptionsLabelText size={optionsSize} hasCursor={item.value === cursorValue}>
                          {item.label}
                        </OptionsLabelText>
                      </Radio>
                    </OptionsListItem>
                  ))}
                </RadioGroup>
              </OptionsList>
              <Box mt={spacerHeightBottom} bg={spacerBg} />
            </PosRelative>
          </Flyout>
        )}
      </OnClickOutside>
    </FormElement>
  )
}

const WithBadge = withBadge(DropdownSelectComponent)
WithBadge.type = 'DropdownSelect'

export const DropdownSelect = withFormElementProps(memo(WithBadge))

const WithButton = withButton(DropdownSelectComponent)
WithButton.type = 'DropdownSelect'

export const DropdownSelectMenu = withFormElementProps(memo(WithButton))

DropdownSelectComponent.propTypes = {
  appearance: string,
  defaultValue: oneOfType([string, number]),
  debounce: number,
  items: arrayOf(
    propsShape({
      label: string.isRequired,
      value: oneOfType([string, number]).isRequired,
      disabled: bool,
    })
  ).isRequired,
  keepButtonText: bool,
  leftAlign: bool,
  name: string.isRequired,
  optionsListMaxHeight: oneOfType([string, array]),
  optionsListPlacement: oneOf([
    'topStart',
    'rightStart',
    'bottomStart',
    'leftStart',
    'topEnd',
    'rightEnd',
    'bottomEnd',
    'leftEnd',
  ]),
  optionsMinWidth: oneOfType([string, array]),
  optionsSize: string,
  placeholder: string,
  shape: string,
  size: string,
  stretch: bool,
  value: oneOfType([string, number]),
  tag: string,
  disabled: bool,
}

DropdownSelectComponent.defaultProps = {
  appearance: 'lightgray',
  defaultValue: null,
  debounce: null,
  optionsListMaxHeight: null,
  optionsListPlacement: 'bottomStart',
  optionsMinWidth: null,
  optionsSize: 'medium',
  keepButtonText: false,
  leftAlign: true,
  placeholder: 'Bitte wählen',
  shape: 'square',
  size: 'medium',
  stretch: true,
  value: null,
  disabled: false,
  tag: 'div',
}
