import * as defaultVariables from '../default/variables'
import * as defaultUiElements from '../default/uiElements'

import * as themeVariables from './variables'
import * as themeUiElements from './uiElements'
import { concatThemeValues } from 'theming/util/themeHelper'

const variables = concatThemeValues(defaultVariables, themeVariables)

const uiElements = {
  card: concatThemeValues(defaultUiElements.getCard(variables), themeUiElements.getCard(variables)),
  badge: concatThemeValues(defaultUiElements.getBadge(variables), themeUiElements.getBadge(variables)),
  buttonIcons: concatThemeValues(
    defaultUiElements.getButtonIcons(variables),
    themeUiElements.getButtonIcons(variables)
  ),
  checkboxBar: concatThemeValues(
    defaultUiElements.getCheckboxBar(variables),
    themeUiElements.getCheckboxBar(variables)
  ),
  controlsPanel: concatThemeValues(
    defaultUiElements.getControlsPanel(variables),
    themeUiElements.getControlsPanel(variables)
  ),
  controlsSegment: concatThemeValues(
    defaultUiElements.getControlsSegment(variables),
    themeUiElements.getControlsSegment(variables)
  ),
  datepicker: concatThemeValues(
    defaultUiElements.getDatepicker(variables),
    themeUiElements.getDatepicker(variables)
  ),
  dropdown: concatThemeValues(
    defaultUiElements.getDropdown(variables),
    themeUiElements.getDropdown(variables)
  ),
  flex: concatThemeValues(defaultUiElements.getFlex(variables), themeUiElements.getFlex(variables)),
  flyout: concatThemeValues(defaultUiElements.getFlyout(variables), themeUiElements.getFlyout(variables)),
  grid: concatThemeValues(defaultUiElements.getGrid(variables), themeUiElements.getGrid(variables)),
  header: concatThemeValues(defaultUiElements.getHeader(variables), themeUiElements.getHeader(variables)),
  layout: concatThemeValues(defaultUiElements.getLayout(variables), themeUiElements.getLayout(variables)),
  modal: concatThemeValues(defaultUiElements.getModal(variables), themeUiElements.getModal(variables)),
  navigationMain: concatThemeValues(
    defaultUiElements.getNavigationMain(variables),
    themeUiElements.getNavigationMain(variables)
  ),
  navigationMeta: concatThemeValues(
    defaultUiElements.getNavigationMeta(variables),
    themeUiElements.getNavigationMeta(variables)
  ),
  scrollbar: concatThemeValues(
    defaultUiElements.getScrollbar(variables),
    themeUiElements.getScrollbar(variables)
  ),
  switches: concatThemeValues(
    defaultUiElements.getSwitches(variables),
    themeUiElements.getSwitches(variables)
  ),
  table: concatThemeValues(defaultUiElements.getTable(variables), themeUiElements.getTable(variables)),
  taglist: concatThemeValues(defaultUiElements.getTaglist(variables), themeUiElements.getTaglist(variables)),
  uiActionElements: concatThemeValues(
    defaultUiElements.getUiActionElements(variables),
    themeUiElements.getUiActionElements(variables)
  ),
}

const themeDark = {
  name: 'themeDark',
  breakpoints: variables.breakpoints,
  colors: {
    base: {
      ...variables.colors,
    },
    theme: {
      ...variables.themeColors,
    },
  },
  radii: variables.radii,
  shadow: variables.shadow,
  sizes: variables.sizes,
  spaces: variables.spaces,
  card: uiElements.card,
  ...variables.typography,
  badge: uiElements.badge,
  buttonIcons: uiElements.buttonIcons,
  checkboxBar: uiElements.checkboxBar,
  controlsPanel: uiElements.controlsPanel,
  controlsSegment: uiElements.controlsSegment,
  datepicker: uiElements.datepicker,
  dropdown: uiElements.dropdown,
  ...uiElements.flex,
  flyout: uiElements.flyout,
  ...uiElements.grid,
  header: uiElements.header,
  layout: uiElements.layout,
  modal: uiElements.modal,
  navigationMain: uiElements.navigationMain,
  navigationMeta: uiElements.navigationMeta,
  scrollbar: uiElements.scrollbar,
  switches: uiElements.switches,
  table: uiElements.table,
  taglist: uiElements.taglist,
  uiActionElements: uiElements.uiActionElements,
}

export default themeDark
