import { useState, useEffect, useRef, useCallback } from 'react'
import useDebounce from './useDebounce'
import { warnOnce, isProduction, isUndefined } from 'lib/util'

export const useDebouncedUpdates = (value, onChange, delay = 100, name) => {
  const countSentChanges = useRef(0)
  const firstRender = useRef(true)
  const [localValue, setLocalValue] = useState(value)
  const localChangeEmitter = useRef()
  const [localOnChange, setLocalOnChange] = useState(() => onChange)
  const prevOnChange = useRef(null)
  const prevLocalValue = useRef(null)
  const debouncedLocal = useDebounce(localValue, delay)

  const setLocalOnChangeAndChangeEmitter = useCallback((value, changeEmitter) => {
    setLocalValue((prevValue) => {
      prevLocalValue.current = prevValue
      return value
    })
    localChangeEmitter.current = changeEmitter
  }, [])

  const inUncontrolledEnvironment = useRef(isUndefined(value))
  const externalValUndefined = isUndefined(value)

  if (!isProduction && inUncontrolledEnvironment.current !== externalValUndefined) {
    const from = inUncontrolledEnvironment.current ? 'uncontrolled' : 'controlled'
    const to = inUncontrolledEnvironment.current ? 'controlled' : 'uncontrolled'
    const msg = `useDebouncedUpdates: hook used in ${from} environment switch to ${to} environment`
    warnOnce('useDebouncedUpdates', msg).then(() => console.trace())
  }

  useEffect(() => {
    setLocalOnChange((prevLocalOnChange) => {
      prevOnChange.current = prevLocalOnChange
      return onChange
    })
  }, [onChange])

  useEffect(() => {
    let timeout = null
    if (delay === null) {
      return
    }
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    // needed to prevent loop if effect triggers due to "onchange" change only (e.g. if onchange is not a callback...)
    if (prevOnChange.current !== localOnChange && prevLocalValue.current === debouncedLocal) {
      prevOnChange.current = localOnChange
      return
    }

    if (typeof localOnChange === 'function') {
      countSentChanges.current++
      timeout = setTimeout(() => {
        countSentChanges.current = 0
      }, delay + 500)
      localOnChange(debouncedLocal, localChangeEmitter.current)
    }

    return () => {
      if (timeout !== null) {
        clearTimeout(timeout)
      }
    }
  }, [delay, debouncedLocal, localOnChange])

  useEffect(() => {
    if (delay === null) {
      return
    }
    const ignoreExternalChange = countSentChanges.current > 0
    countSentChanges.current = countSentChanges.current > 0 ? countSentChanges.current - 1 : 0
    if (ignoreExternalChange) {
      return
    }
    setLocalValue((prevValue) => {
      prevLocalValue.current = prevValue
      return value
    })
  }, [delay, value])

  if (delay === null) {
    return [value, localOnChange]
  } else if (inUncontrolledEnvironment.current) {
    return [undefined, setLocalOnChangeAndChangeEmitter]
  } else {
    return [localValue, setLocalOnChangeAndChangeEmitter]
  }
}
