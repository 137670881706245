import React from 'react'

import { ButtonPrimitive } from '../../SubComponents'

import { defaultProps } from './defaultProps'
import { appearance, Icon } from './defaultPropTypes'

export const ButtonUp = ({ appearance, direction, Icon, onClick, onMouseLeave, size, shape }) => {
  return (
    <ButtonPrimitive
      appearance={appearance}
      direction={direction}
      icon={{ Icon, type: 'shady' }}
      iconPosition={{ top: '1px' }}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      size={size}
      shape={shape}
    />
  )
}

ButtonUp.defaultProps = {
  ...defaultProps,
}

ButtonUp.propTypes = {
  appearance,
  Icon,
}
