export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const stringifyEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b)

/** Finds the path from a node to its root and yields nodes on its way */
export function* ancestors(node) {
  if (node === null) return
  yield node
  yield* ancestors(node.parentNode)
}

export const sum = (arr) => arr.reduce((a, b) => a + b, 0)

/** Filter an array by distinct values
 *
 * For use in Array.proptotype.filter
 *
 * Example: Filter an Array of objects by unique properties
 *   arr.filter(distinctBy('id'))
 * Example: Use a function to identify duplicates
 *   arr.filter(distinctBy((o) => o.id.slice(0, 5)))
 */
export function distinctBy(identifier) {
  const idFunc = (o) => (typeof identifier === 'function' ? identifier(o) : o?.[identifier])
  return (a, idx, arr) => arr.findIndex((o) => idFunc(o) === idFunc(a)) === idx
}

/** Returns a new array with unique values only */
export const unique = (arr) => Array.from(new Set(arr))
/** Unique function for use in Array.prototype.filter */
export const uniqueFilter = (value, index, arr) => arr.indexOf(value) === index
export const difference = (a, b) => a.filter((x) => !b.includes(x))

/** Returns a negated version of the passed function */
export const not =
  (func) =>
  (...args) =>
    !func(...args)
/** Negates the first argument. Useful for toggling boolean react state */
export const toggle = (arg) => !arg
export const increment = (i) => i + 1
export const decrement = (i) => i - 1

export const isUndefined = (o) => typeof o === 'undefined'
export const isDefined = not(isUndefined)
export const isNullOrUndefined = (o) => typeof o === 'undefined' || o === null
export const isFunction = (o) => typeof o === 'function'
export const isArray = Array.isArray
export const makeArray = (x) => (isArray(x) ? x : [x])
export const noop = () => {}
export const isPromise = (o) => isFunction(o?.then)

export const isProduction = process.env.NODE_ENV === 'production'

export { warnOnce } from './warnOnce'

/** Evaluates func and returns if it throws an exception */
export function throws(func) {
  try {
    func()
    return false
  } catch {
    return true
  }
}
export const numToAlpha = (num) => String.fromCharCode((num % 26) + 64)

/** Recursively sum values of an object
 * Inspired by: https://stackoverflow.com/q/42488048
 */
export function deepMergeSum(obj1, obj2) {
  const keys = new Set(Object.keys(obj1).concat(Object.keys(obj2)))
  const result = {}

  for (const key of keys) {
    const a = obj1[key]
    const b = obj2[key]
    if (typeof a === 'undefined') {
      result[key] = b
      continue
    }
    if (typeof b === 'undefined') {
      result[key] = a
      continue
    }
    if (typeof a === 'object') {
      result[key] = deepMergeSum(a, b)
    } else {
      result[key] = a + b
    }
  }
  return result
}
export const sumObjectsByKeys = (...objects) => objects.reduce((acc, cur) => deepMergeSum(acc, cur), {})

/** Copy an object except for certain keys */
export function omit(obj, keys) {
  keys = makeArray(keys)
  const cloned = { ...obj }
  for (const key of keys) {
    delete cloned[key]
  }
  return cloned
}

/** Copy an object except for keys that resolve to nullish */
export function omitNullishValues(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => !isNullOrUndefined(value)))
}

/** Copy an object except for keys that resolve to null */
export function omitNullValues(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== null))
}

/** Copy selected keys of an object to a new object */
export function pick(obj, keys) {
  keys = makeArray(keys)
  const copy = {}
  for (const key of keys) {
    if (!isUndefined(obj[key])) {
      copy[key] = obj[key]
    }
  }
  return copy
}

export function objectFilter(obj, func) {
  const copy = {}
  for (const [key, val] of Object.entries(obj)) {
    if (func(key, val)) {
      copy[key] = obj[key]
    }
  }
  return copy
}

export const isEmpty = (obj) => {
  for (const key in obj) {
    return false
  }
  return true
}

export const sortKeys = (obj, sortFunc) => {
  if (Array.isArray(sortFunc)) {
    const keys = sortFunc
    sortFunc = (a, b) => {
      let idxA = keys.indexOf(a)
      if (idxA === -1) idxA = keys.length
      let idxB = keys.indexOf(b)
      if (idxB === -1) idxB = keys.length
      return idxA - idxB
    }
  }
  let clone = {}
  for (const key of Object.keys(obj).sort(sortFunc)) {
    clone[key] = obj[key]
  }
  return clone
}

/**
 * Returns all possible combinations of the arrays elements
 *
 * Returns an array with n-tuples where n is the number of arrays provided
 * Inspiration: https://stackoverflow.com/a/4331713
 */
export function cartesianProduct(...arrs) {
  const numElements = arrs.reduce((agg, cur) => agg * cur.length, 1)
  const result = new Array(numElements)

  let curDivisor = 1
  const divisors = [1]
  for (const arr of arrs.slice(1).reverse()) {
    curDivisor *= arr.length
    divisors.push(curDivisor)
  }

  for (let n = 0; n < numElements; n++) {
    const nTuple = new Array(arrs.length)
    for (let i = 0; i < arrs.length; i++) {
      const arr = arrs[i]
      const divisor = divisors[i]
      const idx = Math.floor(n / divisor) % arr.length
      nTuple[i] = arr[idx]
    }
    result[n] = nTuple
  }
  return result
}

export function zip(...arrs) {
  const resultLength = Math.min(...arrs.map((a) => a.length))
  return new Array(resultLength).fill(0).map((_, i) => arrs.map((a) => a[i]))
}

/** Immutably push or pop value from array depending on its presence */
export function togglePresence(arr, value, equals = (a, b) => a === b) {
  const idx = arr.findIndex((el) => equals(el, value))
  const isValueInArray = idx !== -1

  if (isValueInArray) {
    return [...arr.slice(0, idx), ...arr.slice(idx + 1)]
  } else {
    return [...arr, value]
  }
}

export const replaceInArrayImmutably = (arr, idx, value) => {
  return [...arr.slice(0, idx), value, ...arr.slice(idx + 1)]
}
export const swapInArrayImmutably = (arr, idxA, idxB) => {
  if (idxA > idxB) {
    // ensure A is smaller than B
    const tmp = idxA
    idxA = idxB
    idxB = tmp
  }
  return [...arr.slice(0, idxA), arr[idxB], ...arr.slice(idxA + 1, idxB), arr[idxA], ...arr.slice(idxB + 1)]
}
export const removeInArrayImmutably = (arr, idx) => {
  return arr.filter((elem, i) => i !== idx)
}

export const insertInArrayImmutably = (arr, idx, value) => {
  return [...arr.slice(0, idx), value, ...arr.slice(idx)]
}

export const defer =
  (func) =>
  (...args) =>
    setTimeout(() => func(...args), 0)

export const cloneJson = (obj) => JSON.parse(JSON.stringify(obj))

export const patchSetStateObject = (key, value) => (prev) => ({ ...prev, [key]: value })
