import axios from 'axios'
import appConfig from 'config/appConfig'
import { ssoLogout } from 'lib/sso'

import { storeManager } from 'lib/flux-store'

const updateLastRefreshToken = () => {
  localStorage.setItem('lastRefreshToken', Date.now())
}
const unsetLastRefreshToken = () => {
  localStorage.removeItem('lastRefreshToken')
}

export const backend = axios.create({
  baseURL: appConfig.bgagServicesUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-app-token': appConfig.randomAppToken,
    'x-app-front': appConfig.frontend,
  },
  withCredentials: true,
})

backend.interceptors.request.use((config) => {
  const l3token = sessionStorage.getItem('l3token')
  if (l3token) {
    config.headers['x-l3-token'] = l3token
  }
  return config
})
backend.interceptors.response.use(
  async (response) => {
    /** Test if login route, then do partial reset on stores. */
    if (response.config.url.indexOf('/login') >= 0) {
      await storeManager.partialResetAll()
    }

    /** Test if route implies update of lastRefreshToken. */
    if (response.config.url.indexOf('/login') >= 0 || response.config.url.indexOf('/authn/refresh') >= 0) {
      updateLastRefreshToken()
    }

    /** Test if route implies removal of lastRefreshToken. */
    if (response.config.url.indexOf('/logout') >= 0) {
      unsetLastRefreshToken()
    }

    /** set l3token to sessionStorage if given */
    const l3token = response.headers['x-l3-token']
    if (typeof l3token === 'string' && l3token.length) {
      sessionStorage.setItem('l3token', l3token)
    }

    return response
  },
  /** Refresh the page on 401 responses. */
  async (err) => {
    const isNetworkError = err.code === 'ERR_NETWORK'
    const path = err.response?.config?.url
    if (
      !isNetworkError &&
      err.response?.status === 401 &&
      path !== '/authn/login' &&
      path !== '/singleSignOn/login'
    ) {
      const isRedirecting = ssoLogout()
      if (!isRedirecting) {
        return window.location.reload()
      }
    }
    return Promise.reject(err)
  }
)

const Config = {
  topics: (addAuthz) => backend.get(`/config/topics${addAuthz ? '?addAuthz=true' : ''}`),
  byType: (type) => backend.get(`/config/${type}`),
  tenantCategories: () => backend.get('/config/validTenantCategories'),
  propertyTypes: () => backend.get('/config/validPropertyTypes'),
  dataSourcesYears: (areaType = '') => backend.get(`/config/dataSourcesYears/${areaType}`),
  dataSourcesYearsBySources: (dataSources, areaTypes) =>
    backend.post(`/config/dataSourcesYears`, { dataSources, areaTypes }),
}

const Documentation = {
  getDocumentation: ({ userId = null, groupId = null }) => {
    const query = []
    if (userId !== null) {
      query.push('userId=' + userId)
    }
    if (groupId !== null) {
      query.push('groupId=' + groupId)
    }
    return backend.get(`documentation/openApi/3.0${query.length ? `?${query.join('&')}` : ''}`)
  },
}

const Auth = {
  login: (credentials) => backend.post('/authn/login', credentials),
  isLoggedIn: () => backend.get('/authn/isLoggedIn'),
  loginWithToken: (token) => backend.get(`/authn/login/${token}`),
  loginWithL3Token: (token) => backend.get(`/authn/login/l3token/${token}`),
  refresh: () => backend.get('/authn/refresh'),
  logout: () => backend.get('/authn/logout'),
  registerTestAccount: (data) => backend.post('/authn/registerTestAccount', data),
  registerTestAccountL3Plus: (data) => backend.post('/authn/registerTestAccountL3Plus', data),
  registerTestAccountDeveloper: (data) => backend.post('/authn/registerTestAccountDeveloperProfiles', data),
  convertDevToTestAccount: () => backend.get('/authn/developerProfilesToTestAccount'),
  currentPermissions: () => backend.get('/authz/user'),
  templateGroups: (path) => backend.post('/authz/templateGroups', { path }),
  currentUser: () => backend.get('/authn/user'),
  availableAreas: ({ addNames = true } = {}) => backend.get(`/authz/availableAreas?addNames=${addNames}`),
  save: (user) => backend.put('/authz/user', { user }),
  validateToken: ({ userId, token, type }) => backend.post('/authn/validateToken/', { userId, token, type }),
  testPasswordStrength: (password) => backend.post('/authn/testPasswordStrength/', { password }),
  setPassword: ({ password, userId, token, type }) =>
    backend.post('/authn/setPassword/', { password, userId, token, type }),
  sendForgotPasswordMail: ({ email }) => backend.post('/authn/sendForgotPasswordMail/', { email }),
}

const SingleSignOn = {
  login: (data) => backend.post('/singleSignOn/login', data),
  getClientConfig: (token) => backend.get(`/singleSignOn/clientConfig/${token}`),
}

const Areas = {
  all: () => backend.get(`/area/all`),
  byType: (type, returnGeom = false) => backend.get(`/area/${type}?returnGeom=${returnGeom}`),
  byTypeAndGac: ({ type, gac, returnGeom = false }) =>
    backend.get(`/area/${type}?gac=${gac}&returnGeom=${returnGeom}`),
  byName: (name) => backend.post(`/area/byName`, { name }),
  byGac: (gac, body) => backend.post(`/area/byGac`, { gac, ...body }),
}

const Location = {
  suggestions: ({ query, countries = 'DE' }) =>
    backend.get(`/location/suggestions?query=${query}&countries=${countries}`),
  nearby: (body) => backend.post(`/location/nearby`, body),
  reverse: (obj) => backend.post(`/location/reverse`, obj),
  byGac: (gac) => backend.get(`/location/byGac/${gac}`),
}

const I18n = {
  loadNamespace: (language, namespace) => backend.get(`/i18n/${language}/${namespace}`),
  upsert: (changes) => backend.post(`/i18n`, { changes }),
  postLog: (body) => backend.post('/i18nlog', body),
  getLog: () => backend.get('/i18nlog'),
}

const Data = {
  getData: (obj) => backend.post(`/data`, obj),
  getDataAverageByCityType: (obj) => backend.post(`/data/averageByCityType`, obj),
}

const Text = {
  getMarketText: ({ textTags, gacs }) => backend.post(`/text`, { textTags, gacs }),
  getVersion: () => backend.get('/text/version'),
}

const Geometry = {
  buildingBlock: (type, body) => backend.post(`/geometry/buildingBlock/${type}`, body),
  KGS12: (type, body) => backend.post(`/geometry/kgs12/${type}`, body),
  areas: (type, body) => backend.post(`/geometry/${type}`, body),
  area: (type) => backend.get(`/geometry/area/${type}`),
  actuallyAreas: (body) => backend.post(`/geometry/area`, body),
}

const L3Plus = {
  zonesByUnitId: (unitId) => backend.get(`/l3plus/zonesByUnitId/${unitId}`),
  zonelist: () => backend.get(`/l3plus/zonelist`),
  submarkets: (params) => backend.post(`/l3plus/submarkets`, params),
  retailUnits: (zoneId) => backend.get(`/l3plus/retailUnits/${zoneId}`),
  retailUnitsByZone: (zone) => backend.post(`/l3plus/retailUnitsByZone`, { zoneName: zone }),
  sendComment: (comment) => backend.post(`/l3plus/comment`, { comment }),
  favorites: () => backend.get('/l3plus/favorites'),
  setFavorite: (id, stars) => backend.post('/l3plus/favorites', { id, stars }),
  tokenizedLink: (id) => backend.get(`/l3plus/tokenizedLink/${id}`),
  tokenizedLinks: () => backend.get('/l3plus/tokenizedLinkList'),
  saveLink: (link) => backend.post('/l3plus/tokenizedLink', link),
}

const Poi = {
  airport: (body) => backend.post(`/poi/airport`, body),
  parking: (body) => backend.post(`/poi/parking`, body),
  transport: (body) => backend.post(`/poi/public_transport`, body),
}

const RiwisObject = {
  byType: ({ type = 'common', params }) => backend.post(`/object/${type}`, params),
  byId: ({ id, countryCode, type }) => backend.get(`/object/${id}/${countryCode}${type ? '/' + type : ''}`),
}

const Queue = {
  getJob: (jobId) => backend.get(`/queue/job/${jobId}`),
  getJobData: (jobId) => backend.get(`/queue/jobData/${jobId}`),
  getUserJobs: (userId, template) =>
    backend.get(`/queue/jobsOfUser/${userId}/${encodeURIComponent(template)}`),
  getFile: (jobId) => backend.get(`/queue/jobDownload/${jobId}`),
}

const Export = {
  exportXls: (body) => backend.post('/export/xls', body),
  exportData: (filetype, body) =>
    backend.post(`/export/rawData${filetype.charAt(0).toUpperCase() + filetype.slice(1)}`, body),
  exportObjects: (body) => backend.post('/export/objects', body),
  exportDeveloperProfileObjects: (body) => backend.post('/export/developerProfileObjects', body),
  exportDeveloperMonitorObjects: (body) => backend.post('/export/developerMonitorObjects', body),
  exportRetailMarketsXls: (body) => backend.post('/export/retailMarketsXls', body),
  exportRetailMarketsEhinfoXls: (body) => backend.post('/export/retailMarketsEhinfoXls', body),
  exportRetailMarketsOSMSpecialCaseForInternalUsers: (body) =>
    backend.post('/export/retailMarketsExcelDownloadOSMSpecialCaseForInternalUsers', body),
  createPdf: (body) => backend.post('/export/pdf', body),
  createImage: (body) => backend.post('/export/image', body),
  getPdfStatus: (jobId) => backend.get(`/export/pdf/status/${jobId}`),
  exportWebtrackingRecords: (id, language) => backend.get(`/export/webtracking/${id}?lang=${language}`),
}

const Admin = {
  getUserList: () => backend.get('/admin/user'),
  exportUserlistCsv: (ids) => backend.post('/admin/user/csvExport', { ids }),
  importUser: (userList) => backend.post('/admin/user/import', { userList }),
  getUser: (userId) => backend.get(`/admin/user/${userId}`),
  getUserHistory: (userId) => backend.get(`/admin/user/history/${userId}`),
  saveUser: (user) => backend.post('/admin/user', user),
  deleteUser: (userId) => backend.delete(`/admin/user/${userId}`),
  sendUserInvite: (userId) => backend.get(`/admin/user/sendInvite/${userId}`),
  getUserPermissions: (userId) => backend.get(`/admin/userPermissions/${userId}`),
  getUserPreview: (user) => backend.post('/admin/userPermissionsPreview', user),
  getRoleList: () => backend.get('/admin/role'),
  getRole: (roleId) => backend.get(`/admin/role/${roleId}`),
  saveRole: (role) => backend.post('/admin/role', role),
  deleteRole: (roleId) => backend.delete(`/admin/role/${roleId}`),
  getGroupList: () => backend.get('/admin/group'),
  getGroup: (groupId) => backend.get(`/admin/group/${groupId}`),
  saveGroup: (group) => backend.post('/admin/group', group),
  deleteGroup: (groupId) => backend.delete(`/admin/group/${groupId}`),
  getGroupPermissions: (groupId) => backend.get(`/admin/groupPermissions/${groupId}`),
  getGroupPreview: (group) => backend.post('/admin/groupPermissionsPreview', group),
  bulkEditUser: (ids, edit) => backend.post('/admin/bulk/user/edit', { ids, ...edit }),
  bulkSendUserInvite: (ids) => backend.post('/admin/bulk/user/sendInvite', { ids }),
  bulkDeleteUser: (ids) => backend.post('/admin/bulk/user/delete', { ids }),
  getWebtrackingUrls: () => backend.get('/admin/webtracking/list'),
  getWebtrackingUrl: (id) => backend.get(`/admin/webtracking/${id}`),
  saveWebtrackingUrl: (id, webtrackingUrl) => backend.post(`/admin/webtracking/${id}`, webtrackingUrl),
  deleteWebtrackingUrl: (id) => backend.delete(`/admin/webtracking/${id}`),
}

const AppState = {
  get: (key) => backend.get(`/appState/riwis/${key}`),
  save: (key, state, partial = false) => backend.post(`/appState/riwis/${key}`, { state, partial }),
}

const Drivingdistance = {
  get: (data) => backend.post('/drivingdistance', data),
}

const Traveltime = {
  get: (data) => backend.post('/traveltime', data),
}

const PropertyIndices = {
  getMaxYear: () => backend.get('/propertyIndices/maxYear'),
  getReecoxes: (time) => backend.post('/propertyIndices/reecoxes', time),
  getClimateIndices: (time) => backend.post('/propertyIndices/climateIndices', time),
}

const Hedonic = {
  getResidentialDemandValues: (params) => backend.post('/hedonic/residential/demandValues', params),
  getResidentialLocationsLayer: (gac) => backend.post('/hedonic/residential/locationsLayer', { gac }),
  getResidentialApartmentProspectAtLocation: (params) =>
    backend.post('/hedonic/residential/apartment/prospectAtLocation', { complete: true, ...params }),
  getResidentialHouseProspectAtLocation: (params) =>
    backend.post('/hedonic/residential/house/prospectAtLocation', { complete: true, ...params }),
  getOfficeLocationsLayer: (gac, officeLocationGacs) =>
    backend.post('/hedonicOffice/locationsLayer', { gac, officeLocationGacs }),
  getIndustryLocationsLayer: (gac) => backend.post('/hedonicIndustry/locationsLayer', { gac }),
  getOfficeProspectAtLocation: (params) =>
    backend.post('/hedonicOffice/prospectAtLocation', { complete: true, ...params }),
  getIndustryProspectAtLocation: (params) =>
    backend.post('/hedonicIndustry/prospectAtLocation', { complete: true, ...params }),
}

const HighstreetReport = {
  getShops: (year) => backend.get(`/highstreetReport/shops/${year}`),
  getHighstreetsByGacAndYear: (params) => backend.post('highstreetReport/highstreets', params),
  getShoppingCenterByGacAndYear: (params) => backend.post('highstreetReport/shoppingCenters', params),
  getRents: (year) => backend.get(`/highstreetReport/rents/${year}`),
  getRetailScores: (year) => backend.get(`/highstreetReport/retailScore/${year}`),
  getAllowedYears: () => backend.get('/highstreetReport/allowedYears'),
}

const TopicDefinitions = {
  getTopicDefinitions: (id = null, topicId, language = 'de', countryCodes, ignoreStandalone = true) =>
    backend.post('/topicDefinitions', { id, topicId, language, countryCodes, ignoreStandalone }),
  getTopicDefinitionList: (language = 'de', countryCodes) =>
    backend.post('/topicDefinitions/topicDefinitionList', { language, countryCodes }),
  getAvailableTopicDefinitions: () => backend.get('/topicDefinitions/availableTopicDefinitions'),
}

const Downloads = {
  getDocuments: (type) => backend.get(`/downloads/list/${type}`),
}

const ErrorReport = {
  log: (data) => backend.post('/errorReport/riwis', data),
  get: () => backend.get('/errorReport'),
}

const Matviews = {
  get: () => backend.get('/admin/matviews'),
  refresh: (payload) => backend.post('/admin/matviews/refresh', payload),
  update: (payload) => backend.post('admin/matviews/update', payload),
}

const Geotools = {
  createCollection: (data) => backend.post(`/geotools/collections`, data),
  getCollection: (id) => backend.get(`/geotools/collections/${id}`),
  updateCollection: (id, data) => backend.patch(`/geotools/collections/${id}`, data),
  deleteCollection: (id) => backend.delete(`/geotools/collections/${id}`),
  createFeature: (collectionId, data) => backend.post(`/geotools/collections/${collectionId}/features`, data),
  updateFeature: (collectionId, featureId, data) =>
    backend.patch(`/geotools/collections/${collectionId}/features/${featureId}`, data),
  deleteFeature: (collectionId, featureId) =>
    backend.delete(`/geotools/collections/${collectionId}/features/${featureId}`),
  updateFeatureOrder: (collectionId, order) =>
    backend.post(`/geotools/collections/${collectionId}/features/order`, { order }),
  algebra: (collectionId, payload) =>
    backend.post(`/geotools/collections/${collectionId}/features/algebra`, payload),
}

const RetailMarkets = {
  Projects: {
    getCollection: () => backend.get(`/retail-markets/projects`),
    create: (data) => backend.post(`/retail-markets/projects`, data),
    get: (id) => backend.get(`/retail-markets/projects/${id}`),
    update: (id, data) => backend.patch(`/retail-markets/projects/${id}`, data),
    updateState: (id, data) => backend.patch(`/retail-markets/projects/${id}/state`, data),
    del: (id) => backend.delete(`/retail-markets/projects/${id}`),
    copy: (id) => backend.post(`/retail-markets/projects/${id}/copy`),
    getObjects: (id) => backend.get(`/retail-markets/projects/${id}/objects`),
    getAllObjects: (id) => backend.get(`/retail-markets/projects/${id}/objects-all`),
    getKeyFigures: (id) => backend.get(`retail-markets/projects/${id}/key-figures`),
    getPce: (id) => backend.get(`/retail-markets/projects/${id}/personal-consumer-expenditures`),
    getLegend: (id) => backend.get(`/retail-markets/projects/${id}/legend`),
    getFilter: (id) => backend.get(`/retail-markets/projects/${id}/filter`),
    getPortfolioHahnimmo: (id) => backend.get(`/retail-markets/projects/${id}/portfolio/hahnimmo`),
  },
  getDatasources: () => backend.get('/retail-markets/datasources'),
  pce: (source, geometry) =>
    backend.post(`/retail-markets/personal-consumer-expenditures?source=${source}`, {
      geometries: [geometry],
    }),
}

const Developer = {
  getMonitorOverview: (filter) => backend.post('developer/monitor-overview', { filter }),
  getMonitorDevelopers: (filter) => backend.post('developer/monitor-developers', { filter }),
  getCompletionDates: (filter) => backend.post('developer/monitor-completion-dates', { filter }),
  getDevelopmentVolumes: (filter) => backend.post('developer/monitor-development-volumes', { filter }),
  getObjects: (filter) => backend.post('developer/objects', { filter }),
  getProfileData: (dunsId) => backend.post('developer/profile-data', { dunsId }),
  getProfileStatistics: (filter) => backend.post('developer/profile-statistics', { filter }),
  getDevelopers: () => backend.post('developer/developers'),
  getReportOverviewData: (filter) => backend.post('developer/report-overview-data', { filter }),
  getReportRankingData: (filter) => backend.post('developer/report-ranking-data', { filter }),
}

const Tenure = {
  getHotelBrands: () => backend.get('object/hotelBrands'),
}

const Webtracking = {
  record: (trackingParams) => backend.post('/webtracking/record', trackingParams),
}

const Reports = {
  Schemas: {
    getCollection: () => backend.get('/reports/schemas'),
    getModuleCollection: (module) => backend.get(`/reports/schemas/${module}`),
    create: (data) => backend.post('/reports/schemas', data),
    get: (module, name) => backend.get(`/reports/schemas/${module}/${name}`),
    update: (module, name, data) => backend.patch(`/reports/schemas/${module}/${name}`, data),
    del: (module, name) => backend.delete(`/reports/schemas/${module}/${name}`),
  },
}

const RawData = {
  Collections: {
    getAll: () => backend.get('/rawData/collections'),
    create: (collection) => backend.post('/rawData/collections', collection),
    edit: (id, collection) => backend.patch(`/rawData/collections/${id}`, collection),
    load: (id) => backend.get(`/rawData/collections/${id}`),
  },
}

export default {
  Config,
  Documentation,
  Location,
  Auth,
  Areas,
  Tenure,
  Data,
  Text,
  I18n,
  Geometry,
  Poi,
  L3Plus,
  RiwisObject,
  Queue,
  Export,
  Admin,
  SingleSignOn,
  AppState,
  Drivingdistance,
  Traveltime,
  PropertyIndices,
  Hedonic,
  HighstreetReport,
  TopicDefinitions,
  Downloads,
  ErrorReport,
  Geotools,
  RetailMarkets,
  Matviews,
  Developer,
  Webtracking,
  Reports,
  RawData,
}
