import { css } from 'styled-components'

import { zIndices } from 'config/zIndices'

/**
 * Override highcharts classes. Will be injected in GlobalStyle.js.
 * Take care when updating library!
 */
const highchartsStylesheetOverrides = css`
  .highcharts-tooltip-container {
    z-index: ${zIndices.highchartsTooltip} !important;
  }
`

export default highchartsStylesheetOverrides
