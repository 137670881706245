import React, { useCallback } from 'react'

import { ButtonIcon } from '@ui/Buttons/ButtonIcon'

import { defaultProps } from './defaultProps'
import { appearance, direction, icon, iconPosition, onClick, shape, size } from './defaultPropTypes'

export const ButtonPrimitive = ({ appearance, direction, icon, iconPosition, onClick, shape, size }) => {
  const onMouseLeave = useCallback((event) => {
    event.target.blur()
  }, [])

  return (
    <ButtonIcon
      appearance={appearance}
      direction={direction}
      icon={icon}
      iconPosition={iconPosition}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      size={size}
      iconVariantSize={size}
      shape={shape}
      style={{ flex: 1, height: '100%' }}
    />
  )
}

ButtonPrimitive.defaultProps = defaultProps
ButtonPrimitive.propTypes = {
  appearance,
  direction,
  icon,
  iconPosition,
  onClick,
  shape,
  size,
}
