import styled, { css } from 'styled-components'
import { color, typography, compose } from 'styled-system'

import { typeScale, typeStyle } from '@typography'

const excludeProps = [
  'breakWord',
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'scale',
  'tag',
  'typeScale',
  'typeStyle',
  'meta',
  'text',
  'textAlign',
]

const stylesBreakWord = css`
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
`

export const StyledText = styled.span.withConfig({
  shouldForwardProp: (props) => !excludeProps.includes(props),
})`
  ${compose(color, typography)};
  ${(props) => typeScale(props.typeScale)};
  ${(props) => typeStyle(props.typeStyle)};
  ${(props) => props.breakWord && stylesBreakWord}
  width: 100%;
`
