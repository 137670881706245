export const marketDataInitialGroups = {
  office: [
    'office_rents',
    'office_yields',
    'office_take-up_and_vacancy',
    'office_stock',
    'office_completions',
    'office_space_by_yr_of_completion',
    'GPI_office',
    'employment_svp',
    'office_company_size',
    'office_employment_by_industries',
    'gross_value_added',
    'gross_domestic_product',
    'unemployment_all',
    'office_rents_quarterly',
    'office_yields_quarterly',
    'office_stock_quarterly',
    'office_take-up_and_vacancy_quarterly',
    'office_rents_forecast',
    'office_yields_forecast',
    'office_take-up_forecast',
    'office_take-up_by_industries_forecast',
    'office_vacancy_forecast',
    'office_completions_forecast',
    'GPI_office_forecast',
    'office_employment_forecast',
  ],
  residential: [
    'residential_rents_apartments',
    'residential_multiplier_apartments',
    'residential_rents_o_o_apartments',
    'residential_terrace_houses',
    'residential_family_homes',
    'residential_multi_family',
    'residential_affordability_index_prices',
    'residential_affordability_index_rents',
    'GPI_residential',
    'transactions_apartments',
    'residential_stock',
    'residential_building_completions_new',
    'residential_building_completions_freehold',

    'population',
    'population_change',
    'commuter',
    'households',
    'students',
    'unemployment_all',
    'income',
    'purchasing_power',

    'residential_rents_apartments_quarterly',
    'residential_multiplier_apartments_quarterly',
    'residential_rents_o_o_apartments_quarterly',
    'residential_rents_apartments_forecast',
    'residential_multiplier_apartments_forecast',
    'residential_rents_o_o_apartments_forecast',
    'GPI_residential_forecast',
    'population_forecast',
    'households_forecast',

    'census_sociodemographic',
    'census_households',
    'census_residential_buildings',
    'census_units_residential_buildings',
  ],
  commercial: [
    'logistics_rents',
    'logistics_plots',
    'logistics_yields',
    'logistics_stock',
    'logistics_completions',
    'logistics_take-up',

    'logistics_building_completions_permits',
    'logistics_building_trade_warehouse',

    'GPI_logistics',
    'logistics_gross_value_added',
    'logistics_gross_domestic_product',
    'logistics_employment_svp',
    'logistics_unemployment_all',
    'logistics_rents_quarterly',
    'logistics_yields_quarterly',
    'logistics_rents_forecast',
    'logistics_yields_forecast',
    'GPI_logistics_forecast',
  ],
  retail: [
    'retail_rents',
    'retail_rents_district',
    'retail_yields',
    'GPI_retail',
    'population',
    'population_change',
    'commuter',
    'households',
    'unemployment_all',
    'income',
    'purchasing_power',
    'retail_rents_quarterly',
    'retail_yields_quarterly',
    'retail_rents_forecast',
    'retail_yields_forecast',
    'GPI_retail_forecast',
    'population_forecast',
    'households_forecast',
  ],
  seniorLiving: [
    'residential_rents_apartments',
    'residential_rents_o_o_apartments',
    'residential_multiplier_apartments',
    'population',
    'population_by_age_senior',
    'senior_ratio',
    'senior_nursing_care_statistic',
    'unemployment_all',
    'purchasing_power',
    'senior_purchasing_power',
    'population_forecast',
    'population_by_age_senior_forecast',
  ],
  microLiving: [
    'residential_rents_apartments',
    'residential_affordability_index_rents',
    'residential_multiplier_apartments',
    'residential_stock',
    'residential_building_completions_new',
    'micro_offer_evaluation',
    'population',
    'population_by_age_micro',
    'youth_ratio',
    'population_change',
    'households',
    'students',
    'employment_svp',
    'commuter',
    'tourism',
    'purchasing_power',
    'population_forecast',
    'population_by_age_micro_forecast',
  ],
}
