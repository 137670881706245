import createStore from 'lib/flux-store'

import { getRetailScoreProperties } from './highStreetHelpers'
import {
  getShops,
  getHighstreets,
  getShoppingCenters,
  getRents,
  getRetailScores,
  getPopulation,
  getPopulationPast,
  getSociodemographicData,
  getLocations,
  getDocumentList,
  getAllowedYears,
} from './Cache'

const initialState = {
  dataIsLoading: true,
  isFailure: false,
  data: [],
  locations: {},
  shopCount: 0,
  productGroupEntries: [],
  shopEntries: [],
  cityEntries: [],
  yearFilter: null,
  allowedYears: [],
  filteredData: [],
  highstreets: {
    locationA: null,
    locationB: null,
  },
  shoppingCenters: {
    locationA: null,
    locationB: null,
  },
  sociodemographicData: {
    locationA: null,
    locationB: null,
  },
  locationSizes: {
    locationA: 0,
    locationB: 0,
  },
  modLocations: null,
  documentList: null,
}

const computedDataCache = {}

const actions = {
  fetchInitialData: () => (dispatch) => {
    dispatch({ type: 'setLoading', payload: { dataIsLoading: true } })

    getAllowedYears()
      .then((allowedYears) => {
        const newestYear = allowedYears[allowedYears?.length - 1]
        Promise.all([
          getShops(newestYear),
          getRetailScores(newestYear - 1),
          getRents(newestYear - 1),
          getLocations(),
        ]).then((res) => {
          let [shopsRes, retailScoresRes, rentsRes, locationsRes] = res

          const filteredLocations = filterLocationDuplicates(locationsRes)
          const locationsWithRS = getLocationsWithRS(filteredLocations, retailScoresRes)
          const productGroupEntries = getProductGroupEntries(shopsRes)
          const shopEntries = getShopEntries(shopsRes)
          const shopsWithRetailScore = getShopsWithRetailScore(shopsRes, retailScoresRes)

          const cityEntries = getCityEntries(locationsWithRS, newestYear)

          const payload = {
            shops: shopsWithRetailScore,
            locations: locationsWithRS,
            productGroupEntries,
            shopEntries,
            cityEntries,
            rents: rentsRes,
            allowedYears,
            yearFilter: newestYear,
          }

          computedDataCache[newestYear] = payload

          dispatch({
            type: 'setInitialData',
            payload,
          })
        })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
  fetchDocumentList: () => (dispatch) => {
    getDocumentList('bmo_document').then((documentList) => {
      dispatch({ type: 'setDocumentList', payload: documentList })
    })
  },
  setYearFilter: (yearFilter) => (dispatch) => {
    Promise.all([
      getShops(yearFilter),
      getRetailScores(yearFilter - 1),
      getRents(yearFilter - 1),
      getLocations(),
    ])
      .then((res) => {
        let [shopsRes, retailScoresRes, rentsRes, locationsRes] = res

        let payload = computedDataCache[yearFilter]

        if (!payload) {
          const filteredLocations = filterLocationDuplicates(locationsRes)
          const locationsWithRS = getLocationsWithRS(filteredLocations, retailScoresRes)
          const productGroupEntries = getProductGroupEntries(shopsRes)
          const shopEntries = getShopEntries(shopsRes)
          const shopsWithRetailScore = getShopsWithRetailScore(shopsRes, retailScoresRes)

          const cityEntries = getCityEntries(locationsWithRS, yearFilter)

          payload = {
            shops: shopsWithRetailScore,
            locations: locationsWithRS,
            productGroupEntries,
            shopEntries,
            cityEntries,
            rents: rentsRes,
          }
          computedDataCache[yearFilter] = payload
        }

        payload.yearFilter = yearFilter

        dispatch({
          type: 'setYearFilter',
          payload,
        })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
  setShopCount: (shopCount) => ({ shopCount }),
  setLocationSizes: (locationSize, locationCategory) => ({ locationSize, locationCategory }),
  setModLocations: (modLocations) => ({ modLocations }),
  setHighstreetsAndSC: (gac, year, locationCategory) => (dispatch) => {
    Promise.all([getHighstreets(gac, year), getShoppingCenters(gac, year)]).then((res) => {
      const [highstreets, shoppingCenters] = res
      dispatch({ type: 'setHighstreetsAndSC', payload: { highstreets, shoppingCenters, locationCategory } })
    })
  },
  setSociodemographicData: (gac, year, locationCategory) => (dispatch) => {
    Promise.all([
      getPopulation(gac, year - 2),
      getPopulationPast(gac, year - 5),
      getSociodemographicData(gac, year - 1),
    ])
      .then((res) => {
        const [population, populationPast, sociodemographicData] = res
        const populationDevelopement =
          ((population[0]['data_de.st_population.population_total'] -
            populationPast[0]['data_de.st_population.population_total']) /
            populationPast[0]['data_de.st_population.population_total']) *
          100
        const newSociodemographicData = {
          centrality: sociodemographicData[0]['data_de.st_purchasing_power.centrality'],
          purchasingPowerIndex: sociodemographicData[0]['data_de.st_purchasing_power.purchasing_power_index'],
          unemployment: sociodemographicData[0]['data_de.st_employee.unemployment_percentage'],
          date: sociodemographicData[0]['date'],
          population: population[0]['data_de.st_population.population_total'],
          populationDate: population[0]['date'],
          populationDatePast: populationPast[0]['date'],
          populationDevelopement: populationDevelopement.toFixed(1),
        }
        dispatch({
          type: 'setSociodemographicData',
          payload: { sociodemographicData: newSociodemographicData, locationCategory },
        })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
}

const reducer = {
  setLoading: (state, { payload }) => {
    return { ...state, payload, isFailure: false }
  },
  setFailure: (state, { payload }) => {
    return {
      ...state,
      dataIsLoading: false,
      isFailure: true,
      messages: payload,
    }
  },
  setInitialData: (state, { payload }) => ({
    ...state,
    dataIsLoading: false,
    isFailure: false,
    shops: payload.shops,
    hsAndSc: payload.hsAndSc,
    locations: payload.locations,
    productGroupEntries: payload.productGroupEntries,
    shopEntries: payload.shopEntries,
    cityEntries: payload.cityEntries,
    rents: payload.rents,
    allowedYears: payload.allowedYears,
    yearFilter: payload.yearFilter,
  }),
  setYearFilter: (state, { payload }) => ({
    ...state,
    shops: payload.shops,
    hsAndSc: payload.hsAndSc,
    locations: payload.locations,
    productGroupEntries: payload.productGroupEntries,
    shopEntries: payload.shopEntries,
    cityEntries: payload.cityEntries,
    yearFilter: payload.yearFilter,
  }),
  setShopCount: (state, { shopCount }) => {
    return { ...state, shopCount }
  },
  setLocationSizes: (state, { locationSize, locationCategory }) => {
    const newLocationSizes = { ...state.locationSizes }
    newLocationSizes[locationCategory] = locationSize
    return { ...state, locationSizes: newLocationSizes }
  },
  setModLocations: (state, { modLocations }) => {
    return { ...state, modLocations }
  },
  setHighstreetsAndSC: (state, { payload }) => {
    const { highstreets, shoppingCenters, locationCategory } = payload
    const newHighstreets = { ...state.highstreets }
    newHighstreets[locationCategory] = highstreets
    const newShoppingCenters = { ...state.shoppingCenters }
    newShoppingCenters[locationCategory] = shoppingCenters
    return { ...state, highstreets: newHighstreets, shoppingCenters: newShoppingCenters }
  },
  setSociodemographicData: (state, { payload }) => {
    const { sociodemographicData, locationCategory } = payload
    const newSociodemographicData = { ...state.sociodemographicData }
    newSociodemographicData[locationCategory] = sociodemographicData
    return { ...state, sociodemographicData: newSociodemographicData }
  },
  setDocumentList: (state, { payload }) => {
    return { ...state, list: payload }
  },
}

const filterLocationDuplicates = (locations) => {
  return locations.reduce((arr, location) => {
    const duplicates = locations.filter((loc) => {
      return loc.gac === location.gac
    })
    if (duplicates.length > 1) {
      if (!arr.some((loc) => loc.gac === duplicates[0].gac)) {
        duplicates.sort((a, b) => a.type - b.type)
        arr.push(duplicates[0])
      }
    } else {
      arr.push(duplicates[0])
    }
    return arr
  }, [])
}

const getProductGroupEntries = (shops) => {
  const alreadyExistsList = {}
  return shops
    .reduce((arr, shop) => {
      const tog = shop.typeOfGoods.toString().replace('.', ',')
      const alreadyExists = alreadyExistsList[tog]
      if (!alreadyExists) {
        arr.push({
          label: shop.togLabel,
          value: tog,
        })
        alreadyExistsList[tog] = true
      }
      return arr
    }, [])
    .sort((a, b) => a.label.localeCompare(b.label))
}

const getShopEntries = (shops) => {
  return shops
    .reduce((arr, shop) => {
      const tog = shop.typeOfGoods.toString().replace('.', ',')
      arr.push({
        value: shop.shop,
        productGroup: tog,
        gac: shop.gac,
      })
      return arr
    }, [])
    .sort((a, b) => a.value.localeCompare(b.value))
}

const getCityEntries = (locationsWithRS, year) => {
  return locationsWithRS
    .map((location) => ({
      label: location.name,
      value: { gac: location.gac, color: getRetailScoreProperties(year, location.retailScore).color },
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

const getLocationsWithRS = (locations, retailScores) => {
  return locations.map((location) => {
    const locationCopy = { ...location }
    const obj = retailScores.find((score) => score.gac === locationCopy.gac)
    locationCopy.date = obj.year - 1
    locationCopy.name = locationCopy.name.replace('(Stadt)', '')
    locationCopy.retailScore = obj.retailScore
    return locationCopy
  })
}

const getShopsWithRetailScore = (shops, retailScores) => {
  return shops.map((shop) => {
    const shopCopy = { ...shop }
    shopCopy.typeOfGoods = shopCopy.typeOfGoods.toString().replace('.', ',')
    const obj = retailScores.find((value) => value.gac === shopCopy.gac)
    shopCopy.retailScore = obj.retailScore
    shopCopy.companyType = shopCopy.companyType.toLowerCase()
    return shopCopy
  })
}

export const [HighstreetReportDataContext, HighstreetReportDataProvider, useHighstreetReportDataStore] =
  createStore(reducer, actions, initialState, undefined, 'HighstreetReportDataStore')
