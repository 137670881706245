import React from 'react'
import { useTheme } from 'styled-components'

import { FlexColumn } from '@layout/BuildingBlocks'
import { Text } from '@typography/ContentElements'

import { getThemeValue } from 'theming'
import { array, oneOfType, string } from 'prop-types'

export const MetalineWithComponents = ({
  metaline,
  metalineColor,
  typeScaleMeta,
  typeStyleMeta,
  width,
  flexRowGap,
  children,
}) => {
  const theme = useTheme()
  const space = flexRowGap || getThemeValue(theme, 'spaces.rhythm.vertical.nano')
  return (
    <FlexColumn alignItems="flex-start" flexRowGap={space} width={width}>
      <Text text={metaline} color={metalineColor} typeStyle={typeStyleMeta} typeScale={typeScaleMeta} />
      {children}
    </FlexColumn>
  )
}

MetalineWithComponents.propTypes = {
  metaline: string,
  metalineColor: string,
  typeScaleMeta: string,
  typeStyleMeta: string,
  width: string,
  flexRowGap: oneOfType([string, array]),
}

MetalineWithComponents.defaultProps = {
  metaline: 'Metaline',
  metalineColor: null,
  typeScaleMeta: 'minion',
  typeStyleMeta: 'light',
  width: null,
  flexRowGap: null,
}
