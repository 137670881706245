import React from 'react'
import styled, { useTheme } from 'styled-components'

import { LegendEntry, Cell } from './SubComponents'
import { arrayOf, node, shape, string, oneOfType, object } from 'prop-types'

import { getThemeValue } from 'theming'

const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 ${(props) => props.spaceY};
  margin-top: -${(props) => props.spaceY};
  width: max-content;
`

export const Legend = ({ entries, title }) => {
  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')

  return (
    <StyledTable spaceY={spaces.rhythm.vertical.small}>
      <tbody>
        {title && (
          <tr>
            <Cell typeStyle="medium" typeScale="pica" text={title} span="4" />
          </tr>
        )}
        {entries.map(({ color, content, span }, index) => {
          return <LegendEntry key={index} color={color} content={content} span={span} />
        })}
      </tbody>
    </StyledTable>
  )
}

Legend.propTypes = {
  title: string,
  entries: arrayOf(
    shape({
      color: string,
      content: oneOfType([string, node, object]),
    })
  ),
}

Legend.defaultProps = {
  entries: null,
  title: null,
}
