// live appVersion will be generated on deploy by scripts/pre_build.sh
import version from '../../public/appVersion.json'

export const appVersion = version.current

export const appBaseUrl = process.env.PUBLIC_URL ?? '/'

export const appUrl = window.location.protocol + '//' + window.location.host + appBaseUrl

export const frontend = appBaseUrl + '/' + process.env.NODE_ENV

export const randomAppToken = Array.from({ length: 32 }, () =>
  '0123456789ABCDEF'.charAt(Math.floor(Math.random() * 16))
).join('')

export const bgagServicesUrl = new URL(process.env.BACKEND_URL, appUrl + '/').href

console.debug('RIWIS app configuration:', { bgagServicesUrl, appBaseUrl, appUrl })

console.debug('GIT_BRANCH', process.env.GIT_BRANCH)
console.debug('GIT_VERSION', process.env.GIT_VERSION)
console.debug('GIT_AUTHOR_DATE', process.env.GIT_AUTHOR_DATE)

export default {
  appVersion,
  bgagServicesUrl,
  appBaseUrl,
  appUrl,
  frontend,
  randomAppToken,
  stackedContextMenu: false,
  quietStoreLogger: process.env.REACT_APP_QUIET_STORE_LOGGER === 'true',
}
