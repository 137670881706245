import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { TagListSimpleView } from '@forms/TagList/TagListSimpleView'
import { FlexRow } from '@layout/BuildingBlocks'
import { CopyMedium } from '@typography'
import { Button, ButtonWithIcon } from '@ui/Buttons'
import { Modal, useModalCloseCurrent } from '@ui/Modal'

import { BudiconPlusUi } from 'bgag-budicons'
import { getThemeValue } from 'theming'

const TagListModalViewComponent = ({
  disabled,
  entries = [],
  modalTitle,
  onChange,
  onRemove,
  onReset,
  value = [],
  tagsProps = {},
  suggestionsProps = {},
  appearance,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const closeCurrentModal = useModalCloseCurrent()

  const spaces = getThemeValue(theme, 'spaces')

  return (
    <Modal>
      <Modal.Trigger>
        <ButtonWithIcon
          disabled={disabled}
          stretch={true}
          icon={{
            Icon: BudiconPlusUi,
            type: 'outline',
            strokeWidth: '2px',
            width: '15px',
          }}
          iconWrapper={{
            marginRight: '10px',
          }}
          text={t('actions.actionAdd')}
          type="button"
          shape="oval"
          size="tiny"
          appearance="gray"
        />
      </Modal.Trigger>
      <Modal.View size="taglist">
        <Modal.Title>{modalTitle}</Modal.Title>
        <Modal.Section>
          <CopyMedium mb={spaces.rhythm.vertical.small}>
            {tagsProps.selectedItemsTitle
              ? tagsProps.selectedItemsTitle
              : t('taglist.modal.defaultSelectedItemsTitle')}
            : {` ${value.length}`}
          </CopyMedium>
          <TagListSimpleView
            entries={entries}
            value={value}
            onRemove={onRemove}
            onChange={onChange}
            appearance={appearance}
            tagsProps={tagsProps}
            suggestionsProps={suggestionsProps}
          />
        </Modal.Section>
        <Modal.Footer>
          <FlexRow flex="auto" justifyContent="flex-end" flexColumnGap="8px">
            <Button text={t('reset')} appearance="dark" size="tiny" shape="oval" onClick={onReset} />
            <Button
              text={t('close')}
              appearance="gray"
              size="tiny"
              shape="oval"
              onClick={closeCurrentModal}
            />
          </FlexRow>
        </Modal.Footer>
      </Modal.View>
    </Modal>
  )
}

TagListModalViewComponent.type = 'TagListModalViewComponent'

export const TagListModalView = memo(TagListModalViewComponent)
