import React from 'react'

import { StyledText } from './SubComponents'

import { defaultProps as textProps, defaultPropTypes as textPropTypes } from '@typography'
import { string, number, element, elementType, oneOfType } from 'prop-types'

export const Text = ({
  align,
  color,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  typeScale,
  typeStyle,
  text,
  ...props
}) => {
  return (
    <StyledText
      color={color}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      textAlign={align}
      typeScale={typeScale}
      typeStyle={typeStyle}
      {...props}
    >
      {text}
    </StyledText>
  )
}

Text.propTypes = {
  ...textPropTypes,
  align: string,
  color: string,
  text: oneOfType([string, number, element, elementType]),
  typeScale: string,
  typeStyle: string,
}

Text.defaultProps = {
  ...textProps,
  align: null,
  color: null,
  text: 'Lorem Ipsum',
  typeScale: 'copy',
  typeStyle: 'medium',
}
